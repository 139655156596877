//
// Template Styles
// --------------------------------------------------
.cky-btn-revisit-wrapper {
    z-index: 2 !important;
}
body {

    &.page-template-template-blog,
    &.single-news {
        background-color: $color-gray;
    }

    &.page-template-template-addresses {
        background-color: $color-gray;

        #page-header {
            color: white !important;
            background-color: $color-burgundy !important;

            #breadcrumb svg.breacrumb-separator path {
                fill: white !important;
            }

            div.big {

                p {
                    font-size: inherit !important;
                    line-height: inherit !important;
                }
            }
        }
    }

    &.single-address {
        
        #page-header {
            background-color: $color-burgundy !important;
            color: white !important;

            #breadcrumb svg.breacrumb-separator path {
                fill: white !important;
            }
        }
    }
}

.search-content {

    #searchform {

        .input-group {

            input {
                @media (max-width:1440px) {
                    width: 40rem !important;
                  }
          
                  @media (max-width:1199px) {
                    width: 30rem !important;
                  }
          
                  @media (max-width:991px) {
                    width: 20rem !important;
                  }
          
                  @media (max-width: 576px) {
                    width: 15rem !important;
                  }
            }
        }
    }
}

#main {
    padding-top: 212px; // Header height
    z-index: 15;

    @media (max-width:1920px) {
        padding-top: 189px;
    }

    @media (max-width:1440px) {
        padding-top: 160px;
    }

    @media (max-width:991px) {
        padding-top: 163px;
    }
}

#breadcrumb {
    color: inherit;

    a,
    span {
        color: inherit;
    }

    svg.breacrumb-separator {
        width: 10px;
        height: auto;
    }
}

#page-header {
    width: 100vw;
    margin: 0 auto;
    
    &.w-img {
        
        .content {
            width: 100%;
            max-width: 1920px;
            margin: 0 auto;
            height: auto;
            min-height: calc(80vh - 212px);
            overflow: hidden;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;

            @media (max-width:1920px) {
                min-height: calc(80vh - 189px);
            }

            @media (max-width:1199px) {
                grid-template-columns: 100%;
                min-height: auto;
            }
        }

        .left-side {

            h1 {
                padding-right: 3.333rem;

                @media (max-width:767px) {
                    padding-right: 0;
                }
            }
        }
    }

    .left-side {
        padding-top: 40px;
        padding-bottom: 100px;
        overflow-y: auto;
        height: inherit;

        @media (max-width:1920px) {
            padding-left: 50px;
        }

        @media (max-width: 1440px) {
            padding-left: 40px;
            padding-right: 40px;
            padding-bottom: 40px;
        }
    
        @media (max-width: 991px) {
            padding-left: 30px;
            padding-right: 30px;
        }
    
        @media (max-width: 767px) {
            padding-left: 15px;
            padding-right: 15px;
        }

        h1 {
            margin-top: 2.778rem;
        }

        h2 {
            max-width: 83.3%;

            @media (max-width:1440px) {
                max-width: 100%;
            }
        }
    }

    .right-side {
        height: inherit;
    }
}

#page-header {
    &.resource-header {
        background: linear-gradient(90deg, #925FEB 0%, #925FEB 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);

        @media (max-width:1199px) {
          background: none;
        }

        #breadcrumb {

            svg.breacrumb-separator {
        
              path {
                fill: white;
              }
            }
          }

        h1 {
        margin-bottom: 1.667rem;
        }
        
        .content {
            width: 100%;
            max-width: 1920px;
            margin: 0 auto;
            height: auto;
            min-height: calc(100vh - 212px);
            overflow: hidden;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;

            @media (max-width:1920px) {
                min-height: calc(100vh - 189px);
            }

            @media (max-width:1199px) {
                grid-template-columns: 100%;
                min-height: auto;
            }
        }

        .left-side {
        position: relative;
        padding-left: 50px;
        color: white;

        h2 {
            margin-top: 2.778rem;
        }

        h1 {
            padding-right: 3.333rem;
            margin-top: 2.778rem;
        }

        @media (max-width:1199px) {
            padding-bottom: 150px;
            padding-left: 40px;
            background-color: $color-purple;
        }

        @media (max-width:991px) {
            padding-left: 30px;
        }

        @media (max-width:767px) {
            padding-left: 15px;
        }
        }

        .right-side {
            padding-top: calc(63px + 3.333rem);
            padding-bottom: 4.444rem;
            padding-left: 35px;
            padding-right: 50px;
            overflow-y: auto;

            @media (max-width: 1199px) {
                padding-left: 40px;
                padding-right: 40px;
                padding-top: 40px;
            }

            @media (max-width: 991px) {
                padding-left: 30px;
                padding-right: 30px;
            }

            @media (max-width: 767px) {
                padding-left: 15px;
                padding-right: 15px;
            }

            .resource-content {
                a {
                    color: $color-turquoise !important;
                }
            }

            .resource-file {

                .video {
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    padding-top: 56.25%;

                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                .video,
                .podcast {
                    margin-bottom: 3.333rem;
                }
            }

            p {
                margin-bottom: 1.2rem;
            }

            ul {
                margin: 0;
                margin-bottom: 20px;
                list-style: none;
          
                li {
                    margin-bottom: 5px;
                    position: relative;
                    padding-left: 22px;
            
                    &:last-child {
                    margin-bottom: 0;
                    }
                    &::before {
                    content: "\f061";
                    font-family: 'Font Awesome 6 Pro';
                    font-weight: inherit;
                    color: $color-primary;
                    font-size: 15px;
                    position: absolute;
                    left: 0;
                    top: 5px;
                    //transform: translateY(-50%);
                    }
                }
          
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.number {
    display: grid;
    grid-template-columns: 40% 45% auto;
    text-align: left;
    padding: 0;
    width: auto;
    justify-content: unset;

    span:not(.icon) {
        border-right: 2px solid;
        padding: 17px 15px;

        @media (max-width:1199px) {
            padding: 18px 15px;
        }
    }

    .num {
        border-right: none !important;
    }

    .icon {
        border-left: 2px solid;
        padding: 15px;
        text-align: center;
        max-width: 62px;
        margin-left: auto;

        @media (max-width:1199px) {
            padding: 17px 15px;
        }
    }
}

.filters {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    select {
        background-color: transparent;
        border: 2px solid $color-primary;
        border-radius: 2px;
        padding: 15px;
        padding-right: 35px;
        width: 100%;
        color: $color-primary;
        font-family: inherit;
        -moz-appearance:none;
        -webkit-appearance:none;
        appearance:none;
        position: relative;
        background-image: url('../../assets/images/select-arrow.svg');
        background-position-x: 95%;
        background-position-y: 50%;
        background-repeat: no-repeat;

        &.is-active {
            background-color: $color-primary;
            color: white;
            background-image: url('../../assets/images/select-arrow-active.svg');
        }

        option {
            width: 100%;
        }
    }

    .checkbox-btn {
        border-left: 2px solid $color-primary;
        margin-left: auto;
        padding: 15px;

        input {
            visibility: hidden;

            &:checked~label {

                &::before {
                    background-color: $color-primary !important;

                }
            }
        }

        label {
            position: relative;
            padding-left: 20px;

            &::before {
                position: absolute;
                top: 50%;
                left: -20px;
                transform: translateY(-50%);
                content: "";
                width: 11px;
                height: 11px;
                border-radius: 3px;
                border: 2px solid $color-primary;
                background-color: transparent;
                transition: all .2s linear;
            }
        }
    }

    @media (max-width:767px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1.389rem;
    }
}

.focus,
.helpful-links,
.useful-infos {
    background-color: $color-purple;
    color: white;
    padding: 0.556rem 1.111rem;
    transition: $transition-base;
    height: auto;
    width: 100%;
    overflow: hidden;

    a {
        color: white;
    }

    img {
        position: static !important;
        transform: translateY(0) !important;
        object-fit: contain !important;
        padding: 0 !important;
    }

    .focus-header,
    .links-header,
    .infos-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        img {
            margin-right: 2.222rem;
            max-width: 90px !important;
            height: auto !important;
        }

        svg.arrow {
            position: absolute;
            top: 50%;
            right: 1.111rem;
            transform: translateY(-50%) rotate(0deg);
            transition: $transition-base;

            circle {
                fill: transparent;
            }

            path {
                fill: white;
            }
        }

        &:hover {
            cursor: pointer;
        }

        h3 {
            width: 100%;
            max-width: calc(100% - 145px - 2.222rem);
            text-align: left;
            margin-bottom: 0 !important;
        }
    }

    .focus-content,
    .links-content,
    .infos-content {
        margin-top: 0.833rem;
        padding-left: calc(90px + 2.222rem);
        padding-bottom: 1.2rem;
        display: none;
        visibility: hidden;
        text-align: left;

        .links {
            padding: 0;
            margin: 0;
            list-style: none;

            .link {
                margin-bottom: 7px;
                position: relative;
                width: fit-content;

                svg.list-arrow {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -15px;
                    width: 10px;
                    height: auto;
                }
                
                &:hover {

                    a {
                        padding-left: 6px;
                    }
                }
            }
        }
    }

    &.is-open {

        .focus-header,
        .links-header,
        .infos-header {

            svg.arrow {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        .focus-content,
        .links-content,
        .infos-content {
            display: block;
            visibility: visible;

            @media (max-width:576px) {
                max-height: calc(75vh - 130px);
                overflow-y: scroll;
            }
        }
    }

    &:hover {

        .focus-header,
        .links-header,
        .infos-header {

            svg.arrow {
                
                circle {
                    fill: white;
                }

                path {
                    fill: darken($color-purple, 6%);
                }
            }
        }
    }
}

.address-bloc {

    .infos {

        span.btn {

            @media (max-width:991px) {
                max-height: 52px !important;
            }
        }
    }
}

.address-search {
    background-color: $color-burgundy;
    padding-top: 5.556rem;
    padding-bottom: 8.056rem;

    @media (max-width:991px) {
        padding-bottom: 5.556rem;
    }

    h2 {
        color: $color-gray;
        margin-bottom: 2.222rem;
    }

    .address-form {
        background-color: $color-gray;
        color: $color-burgundy;
        padding: 2.222rem 1.944rem;
        padding-bottom: 3.611rem;

        svg {
            width: 65px;
            height: auto;
        }

        h3 {
            margin-top: 3.333rem;
            margin-bottom: 2.222rem;
        }
    }
}

.all-resources {
    border-color: $color-purple;
    color: white;
    background-color: $color-purple;
    margin-left: auto;
    margin-top: 20px;

    svg {
        margin-left: 20px;

        circle {
            stroke: white;
            transition: $transition-base;
        }
        path {
            fill: white;
            transition: $transition-base;
        }
    }

    &:hover {
        color: $color-purple;
        background-color: transparent;

        svg {
            circle {
                stroke: $color-purple;
            }

            path {
                fill: $color-purple;
            }
        }
    }

    @media (max-width:576px) {
        width: 100%;
    }
}

.resources {

    .resource-block {
        &.addresses {
            
            div:not(.btn) {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                svg {
                    height: 40px;
                    width: auto;
                    margin-right: 12px;
                    
                    path {
                        transition: $transition-base;
                    }
                }

                @media (max-width:767px) {
                    grid-column: 1 / 6;
                }
            }
            
            a {
                display: flex;
                align-items: center;
                justify-content:space-between;

                h3 {
                    padding: 0 !important;
                    border: none !important;
                }

                div {
                    padding: 0.556rem 0.833rem;

                    &.btn {
                        padding: 0.556rem .556rem;
                    }
                }

                &:hover {

                    div:not(.btn) {

                        svg {
                            path {
                                stroke: white;
                            }
                        }
                    }
                }
            }
        }

        .format {
            
            @media (max-width:1440px) {
                min-width: 200px;
            }

            @media (max-width:767px) {
                min-width: fit-content;
            }
        }

        .size {
            
            @media (max-width:1440px) {
                min-width: 120px;
            }

            @media (max-width:767px) {
                min-width: fit-content;
            }
        }

        .title {
            display: flex;
            justify-content: flex-start;
        }

        .description {
            margin-left: 100px;

            @media (max-width:767px) {
                margin-left: 0;
            }
        }

        @media (max-width:767px) {

            span.format,
            h3.format {

                svg {
                    height: 25px !important;
                }
            }

            span, h3 {
                padding: 0.833rem .5rem !important;
            }
            
            &:hover {

                .size {
                    border-color: white !important;
                }
            }

            .title {
                order: 1;
                width: 100%;
                flex-direction: column;
            }
        }
    }
}

.tag {
    color: white;
    padding: 0.833rem;
    text-align: center;
    display: inline-block;
}

#loader {
    width: 50px;
    height: 50px;
    display: inline-block;
    position: relative;
    background: $color-primary;
    box-sizing: border-box;
    animation: spin 1s ease infinite alternate-reverse;
    margin: 0 auto;
}
  
@keyframes spin {
    0% {
        transform: scale(0) rotate(360deg);
    }
    100% {
        transform: scale(1) rotate(0deg);
    }
}

.loader-content {
    text-align: center;
}


.loader-content {
    display: none;
}

#load-more {
    margin: 0 auto;
    margin-bottom: 5.556rem;
    margin-top: 2.778rem;
}

.resource-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 76, 108, .7);
    width: 100vw;
    height: 100vh;
    z-index: 21;
    align-items: center;
    justify-content: center;
    display: none;
    visibility: hidden;
  
    &.is-active {
      display: flex;
      visibility: visible;
    }
  
    .container {
  
      @media (max-width:576px) {
        padding: 0 30px;
      }
    }
  
    .popup-content {
      position: relative;
      background-color: transparent;
      padding: 50px 100px;
  
      @media (max-width:576px) {
        padding: 40px 0;
      }
  
      .popup-close {
        position: absolute;
        top: 50px;
        right: 0;
        font-size: 50px;
        cursor: pointer;
        border: none;
        padding: 0;
        color: white;

        @media (max-width:767px) {
            font-size: 40px;
            top: 0;
        }
      }

      .resource-iframe {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        height: auto;
        width: 100%;
        margin: 0 auto;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
      }
    }
}   

.video-grid {
    margin: 5.5rem 0;
  
    .grid {
        width: 100%;

        &:after {
            content: '';
            display: block;
            clear: both;
        }

        .vid-content {
            height: auto;
            margin-bottom: 30px;
            display: block;
            aspect-ratio: 1;
            cursor: pointer;

            .iframe {
                display: none;
                visibility: hidden;
            }
            
            .title {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                color: white;
                background-color: rgba(0, 76, 108, .7);
                opacity: 0;
                transition: $transition-base;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
            }

            img {
                width: 100%;
                height: auto;
            }

            &:hover {

                .title {
                    opacity: 1;
                }

                .play-icon {
                    opacity: 0;
                }
            }

            .play-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                opacity: 1;
                transition: $transition-base;
            }
        }

        .grid-sizer,
        .vid-content {
            width: calc(20% - 30px);

            @media (max-width: 1199px) {
                width: calc(25% - 30px);
            }

            @media (max-width: 991px) {
                width: calc(100% / 3 - 30px);
            }

            @media (max-width: 767px) {
                width: calc(50% - 30px);
            }

            @media (max-width: 576px) {
                width: 100%;
                margin-bottom: 25px;
            }
        }

        .vid-content {
            &.big {
                width: calc(40% - 30px);

                @media (max-width: 1199px) {
                    width: calc(50% - 30px);
                }
    
                @media (max-width: 991px) {
                    width: calc(100% / 3 * 2 - 30px);
                }
    
                @media (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 25px;
                }
            }
        }
    }
}

.post-bloc {
    
    a {
        padding: 5rem 0;
        border-top: 1px solid $color-primary;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;

        &:hover {
            color: inherit;
        }

        @media (max-width:767px) {
            padding: 4rem 0;
            grid-template-columns: 1fr;
        }
    }

  &:first-of-type {
      a {
        border-top: none;
      }
  }

  .tag {
      margin-bottom: 1.389rem;
      margin-right: 0.556rem;
  }

  .infos {
      border: 2px solid $color-primary;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .date {
          padding: 0.833rem;
          vertical-align: middle;
          display: flex;
          align-items: center;
          justify-content: flex-start;
      }

      .btn {
          padding: 0.35rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 2px solid $color-primary;
          background-color: $color-primary;
  
          svg {
              width: 45px;
              height: auto;
      
              circle {
              transition: $transition-base;
              stroke: white;
              }
      
              path {
              transition: $transition-base;
              fill: white;
              }
          }
      }
  }

    .excerpt {
        font-size: inherit;
        margin-top: 1.944rem;
    }

    @media (max-width:767px) {

        .infos {
            align-items: stretch;

            .date {
                width: 100%;
            }

            .btn {
                margin-left: auto;
                height: auto;
            }
        }
    }
}

#search-results,
#page-not-found {
    background-color: $color-gray;
    padding-bottom: 8.889rem;
    padding-top: 5.556rem;

    .results {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;

        @media (max-width:1199px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:767px) {
            grid-template-columns: 1fr;
        }
    }
}

#page-not-found {
    background-color: white;
    padding-bottom: 8.889rem;
    padding-top: 5.556rem;

    .error-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px;
    }
}

#single-address {

    .intro-bloc {
        background-color: $color-gray;
        padding: 5.556rem 0;

        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;

            @media (max-width:1199px) {
                grid-template-columns: 1fr;
            }

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 2px solid $color-primary;
                transition: $transition-base;
                
                a,
                address {
                    color: $color-primary;
                    transition: none;
                    display: block;
                    width: 100%;
                    padding: 12px;
                }
              
                &:hover:not(.address-element) {
                    background-color: $color-primary;
                    color: white;
              
                    a,
                    address {
                        color: inherit;
                    }
              
                    .icon {
                        border-color: white;
              
                        svg {
                            path {
                                stroke: white;
                            }
                        }
                    }
                }
              
                .icon {
                    padding: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-left: 2px solid $color-primary;
                    transition: $transition-base;
                }
            }
        }

        .info {
            margin-top: 2.353rem;
        }
    }
}

#page-addresses {
    padding-top: 5.556rem;
    padding-bottom: 8.889rem;

    #address-form {
        margin-bottom: 1.667rem;
    }

    .text-infos {
        margin-bottom: 1.667rem;
        max-width: 75%;

        @media (max-width:1440px) {
            max-width: 100%;
        }

        p,
        ul {
            margin-bottom: 0.6rem;

            &:last-child {
                margin-bottom: 0;
            }

            li {
                margin-bottom: 0.2rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        p {
            font-size: inherit;
            line-height: inherit;
        }

        a {
            font-size: inherit;
            line-height: inherit;
        }
    }
}

#page-emergencies {

    .emergency-numbers {
        background-color: $color-gray;
        padding: 5.556rem 0;

        .emergencies {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;

            @media (max-width:991px) {
                grid-template-columns: 1fr;
            }

            .number {
                color: $color-red;
                border-color: $color-red;

                svg {
                    path {
                        stroke: $color-red;
                    }
                }
                
                &:hover {
                    background-color: $color-red;
                    color: white;

                    svg {
                        path {
                            stroke: white;
                        }
                    }
                }
            }
        }
    }
}

#page-glossary {
    margin-top: 5.556rem;
    margin-bottom: 8.889rem;

    .glossary {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 30px;

        @media (max-width:1199px) {
            grid-template-columns: 1fr;
            gap: 0;
        }
    }
}

#page-press {

    .page-content {
        background-color: $color-gray;
        padding: 5.556rem 0;
        padding-bottom: 11rem;
        
        section {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;
        
            h2 {
                max-width: 90%;
                margin-bottom: 1.389rem;
            }
        
            .description {
        
                p {
                    font-size: inherit;
                    line-height: inherit;
                    margin-bottom: 0.6rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                ul {
                    margin: 0;
                    margin-bottom: 0.6rem;
                    padding: 0 0.5rem;
                    list-style: none;
              
                    li {
                      margin-bottom: 0.2rem;
                      position: relative;
                      padding-left: 22px;
              
                      &:last-child {
                        margin-bottom: 0;
                      }
                      &::before {
                        content: "\f061";
                        font-family: 'Font Awesome 6 Pro';
                        font-weight: inherit;
                        color: $color-primary;
                        font-size: 15px;
                        position: absolute;
                        left: 0;
                        top: 3px;
                        //transform: translateY(-50%);
                      }
                    }
              
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
            }
        
            .mails {
                grid-column: 2 / 3;
                margin: 0;
                margin-top: 2rem;
                padding: 0;
                list-style: none;
                display: flex;
                flex-direction: column;
                gap: 20px;

                @media (max-width:991px) {
                    grid-column: auto;
                }
            }
        
            @media (max-width:991px) {
                grid-template-columns: 1fr;
            }
        }
        
        @media (max-width:767px) {
            padding: 4.444rem 0;
            padding-bottom: 6rem;
        }
    }
}

#page-partners {

    .cantons-content {
        padding: 6.667rem 0;
        padding-bottom: 8.889rem;
        background-color: $color-gray;

        h2:not(.large) {
            margin-bottom: 2.222rem;
        }
    }

    .cantons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;

        @media (max-width:1199px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:767px) {
            grid-template-columns: 1fr;
        }

        .child-page-block {
            
            a {
                padding-bottom: 7.222rem;
            }
        }
    }
}

#page-default {

    form.post-password-form {
        margin: 6.5rem 0;

        label {
            position: static;
        }

        input {
            margin-top: .5rem;
        }

        p {
            margin: 0;

            &:nth-child(2) {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: 1rem;
                gap: 1rem;
            }
        }
    }

    #page-content {
        background-color: white;
        margin: 6.667rem 0;
    }

    form .gfield_required {
        display:  initial !important;
    }
}

#page-campaign {
    .page-content {
        background-color: #fff;
        margin-bottom: 6.667rem;
        margin-top: 6.667rem;
    }
}

#page-default,
#page-emergencies,
#single-address {

    .page-content {
        margin: 6.667rem 0;
        background-color: white;

        .container {
            
            &.grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 30px;

                @media (max-width:1199px) {
                    grid-template-columns: 1fr;
                    gap: 0;
                }
            }
        }
    }
}

#page-resource-single {

    .related-resources {
        background-color: $color-gray;
        padding: 8.889rem 0;
        color: $color-purple;
    
        h2 {
            margin-bottom: 2.222rem;
        }
    
    }
}

#page-resources,
#page-pro-resources {
    padding-bottom: 8.889rem;

    #load-more {
        color: $color-purple;
        border-color: $color-purple;
        margin-top: 5.556rem;
        margin-bottom: 0;

        &:hover {
            background-color: $color-purple;
            color: white;
        }
    }

    .resources-search {
        margin: 5.556rem 0;

        h2 {
            margin-bottom: 2.222rem;
        }
    }

    #resources-form {
        color: $color-primary;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 30px;
        row-gap: 20px;
      
        select {
          background-color: transparent;
          border: 2px solid $color-primary;
          border-radius: 2px;
          padding: 15px;
          padding-right: 35px;
          width: 100%;
          color: $color-primary;
          font-family: inherit;
          -moz-appearance:none;
          -webkit-appearance:none;
          appearance:none;
          position: relative;
          background-image: url('../../assets/images/select-arrow.svg');
          background-position-x: 95%;
          background-position-y: 50%;
          background-repeat: no-repeat;

          &.is-active {
            background-color: $color-primary;
            color: white;
            background-image: url('../../assets/images/select-arrow-active.svg');
          }

          &:focus {
            appearance: none;
          }
        }
      
        input {
          grid-column: 1 / 3;
          color: $color-primary;
          border: 2px solid $color-primary !important;
          padding: 15px;
          font-family: inherit;
      
          &::placeholder {
            color: inherit;
          }
        }
      
        .results-number {
          grid-column-start: 3;
          grid-column-end: 4;
          font-size: 1rem;
          line-height: normal;
          display: block;
          margin-top: 5px;
        }
      
        button {
          color: $color-gray;
          border-color: $color-primary;
          background-color: $color-primary;
      
          &:hover {
            color: $color-primary;
            background-color: transparent;
          }
        }
      
        @media (max-width:1440px) {
          grid-template-columns: repeat(2, 1fr);
      
          input {
            grid-column: 1 / 2;
          }
      
          .results-number {
            grid-column-start: 2;
            grid-column-end: 3;
          }
        }
      
        @media (max-width:991px) {
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
    }
}

#page-news-single,
#page-event-single {

    .intro-bloc {
        background-color: white;
    }

    .related-news,
    .next-events {
        padding: 5.556rem 0;

        @media (max-width:767px) {
            padding: 4.444rem 0;
        }
    }
}

#page-event-single {
    background-color: $color-gray;
}

#page-news,
#page-agenda,
#page-pro-agenda {
    margin-bottom: 2.778rem;

    .filters {
        border: 2px solid $color-primary;
        border-radius: 2px;
        margin-top: 5.556rem;
        margin-bottom: 2.778rem;

        select {
            width: 25%;
            border: none;
            border-right: 2px solid $color-primary;

            &:last-of-type {
                border-right: none;
                border-left: 2px solid $color-primary;
            }
        }

        select[name="sort"] {
            width: auto;
            margin-left: auto;
        }

        select[name="archives"] {
            margin-left: auto;
        }

        @media (max-width:767px) {
            border: none;

            select {
                width: 100% !important;
                border: 2px solid $color-primary !important;
                border-radius: 2px;
            }

            .archives-btn {
                width: 100% !important;
                border: 2px solid $color-primary;
                border-radius: 2px;
            }
        }
    }
}

#page-agenda,
#page-pro-agenda {

    .filters {

        select {
            &:last-of-type {
                border-right: 2px solid $color-primary;
                border-left: none;  
            }
        }

        .archives-btn {
            width: 25%;
        }
    }
}

#page-child-has-children,
#page-child-page-simple,
#page-medias {

    .page-content {
        background-color: white;
        margin-bottom: 6.667rem;
        margin-top: 6.667rem;

        @media (max-width:767px) {
            margin: 4.5rem 0;
        }

        .container {
            &.grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 30px;

                @media (max-width:1199px) {
                    grid-template-columns: 1fr;
                    gap: 0;
                }
            }
        }
    }

    .videos-podcasts,
    .videos-imgs {
        margin-bottom: 6.667rem;

        h2 {
            margin-bottom: 1.111rem;
        }

        .video-content,
        .podcast-content,
        .grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 30px;
            margin-top: 1.667rem;

            .video,
            .media-block.vid .wrapper { 
                position: relative; 
                padding-bottom: 56.25%;
                overflow: hidden;
                max-width: 100%;
                height: auto;

                iframe,
                object,
                embed {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            @media (max-width:1199px) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media (max-width:991px) {
                grid-template-columns: 1fr;
            }
        }
    }

    .videos-imgs {
        margin-top: 6.667rem;

        .grid {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        .media-block {
            width: calc(20% - 24px);
            height: auto;

            @media (max-width:1199px) {
                width: calc(25% - 24px);
            }

            @media (max-width:991px) {
                width: calc(100% / 3 - 24px);
            }

            @media (max-width:767px) {
                width: calc(50% - 24px);
            }

            @media (max-width:576px) {
                width: 100%;
            }

            &.vid {
                
                .wrapper {
                    padding-bottom: 100% !important;
                }
            }

            &.imgSlider {
                position: relative;

                .slick-arrow {
                    background-color: transparent;
                    border: 1px solid $color-primary;
                    width: 45px !important;
                    height: 45px !important;
                    &.slider-next {
                        right: 15px;
                    }

                    &.slider-prev {
                        left: 15px;
                    }

                    &.slick-disabled {
                        display: none !important;
                    }
                }

                .img {

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

#page-parent-page,
#page-parent-blocks {

    .child-pages {
        padding-top: 2.222rem;
        padding-bottom: 5.556rem;

        .grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 30px;

            @media (max-width:1199px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width:767px) {
                grid-template-columns: 1fr;
            }
        }
    }
}

#page-pro-homepage {

    .pro-thematics {
        padding-bottom: 8.889rem;

        h2 {
            margin-bottom: 2.222rem;
        }

        .grid {
            padding: 0;
            margin: 0;
            list-style: none;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 30px;

            @media (max-width:1199px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width:767px) {
                grid-template-columns: 1fr;
            }

            .child-page-block {
                height: 100%;
            }
        }
    }
}

#page-homepage,
#page-pro-homepage {
    background-color: $color-gray;

    .shortcuts {
        padding-top: 5.556rem;
        padding-bottom: 9.444rem;

        @media (max-width:576px) {
            padding-bottom: 5.556rem;
        }

        h2 {
            margin-bottom: 2.222rem;
        }

        .grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 30px;

            @media (max-width:1199px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width:576px) {
                grid-template-columns: 100%;
            }
        }

        .shortcut {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            background-color: $color-turquoise;
            color: $color-primary;
            padding: 1.389rem 1.667rem;
            position: relative;
            height: calc(25vw - 100px);
            max-height: 450px;

            @media (max-width:1440px) {
                height: calc(35vw - 50px);
            }

            @media (max-width: 1440px) and (min-width: 1200px) {
                height: calc(25vw - 50px);

                h3 {
                    font-size: 2rem;
                    line-height: 2rem;
                }
            }

            @media (max-width:576px) {
                height: auto;
            }

            svg.arrow {
                position: absolute;
                bottom: 1.389rem;
                right: 1.667rem;

                circle {
                    fill: transparent;
                }

                path {
                    fill: $color-primary;
                }
            }

            svg.replaced-svg {
                path {
                    transition: $transition-base;
                }
            }

            h3 {
                width: 100%;
                max-width: calc(100% - 50px);

                @media (max-width:576px) {
                    margin-top: 1rem;
                }
            }

            &:hover {
                color: $color-turquoise;
                background-color: $color-primary;

                svg.arrow {
                    circle {
                        fill: $color-turquoise;
                    }

                    path {
                        fill: $color-primary;
                    }
                }

                svg.replaced-svg {
                    path {
                        stroke: $color-turquoise;
                    }
                }
            }
        }
    }

    .agenda-news {
        padding-top: 8.889rem;
        padding-bottom: 4.444rem;
        color: $color-burgundy;

        @media (max-width:991px) {
            padding: 6rem 0;
        }

        @media (max-width:767px) {
            border-bottom: 2px solid $color-burgundy;
        }

        a {
            color: inherit;
        }

        h2 {
            margin-bottom: 2.222rem;
        }

        .grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;

            @media (max-width:1199px) {
                grid-template-columns: 1fr;
                gap: 6rem;
            }
        }

        .btn-primary {
            margin-top: 1.389rem;
            margin-left: auto;
            border-color: $color-burgundy;

            &:hover {
                color: $color-gray;
                background-color: $color-burgundy;
            }
        }
    }

    .messages-content {
        color: $color-burgundy;
        padding-bottom: 8.889rem;
        padding-top: 4.444rem;

        @media (max-width:767px) {
            padding: 5.556rem 0;
        }

        h2 {
            margin-bottom: 2.5rem;
        }

        .slider-content {
            position: relative;
        }

        // .slick-list {
        //     height: auto !important;
        // }

        .messages-slider {
            padding: 0;
            margin: 0;
            width: 100%;

            .slick-list {
                height: fit-content !important;
            }

            .slick-track {
                display: flex;
                margin: 0 -15px;

                .slick-slide {
                    height: auto;
                    margin: 0 15px;
                    display: grid !important;
                    align-content: flex-start;

                    img {
                        width: 350px;
                        height: auto;
                    }
                }

                @media (max-width:1199px) {
                    margin: 0;

                    .slick-slide {
                        margin: 0;
                        height: fit-content;
                    }
                }
            }
        }

        .slick-arrow {
            background-color: $color-burgundy;
            &.slider-next {
                right: 0;

                @media (max-width:1199px) {
                    right: -25px;
                }

                @media (max-width:767px) {
                    right: 0;
                }
            }
        }

        .slick-dots {
            bottom: -50px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% - 30px);

            li {
                border-radius: 50% !important;
                button {
                    background-color: transparent !important;
                    border: 2px solid $color-burgundy;
                    width: 1rem !important;
                    height: 1rem !important;
                    opacity: 1 !important;
                    border-radius: 50% !important;
                }

                &.slick-active {
                    background-color: $color-burgundy !important;
                }
            }
        }
    }
}