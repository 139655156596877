//
// Header Styles
// --------------------------------------------------

#header {
	width: 100%;
	background-color: $color-primary;
	padding: 50px 0;
	padding-bottom: 55px;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 20;
	transform-origin: top;
	transition: padding 0.4s linear, max-height 0.2s ease-in-out;
	height: auto;

	@media (min-width: 1199px) {

		&.is-hover {
			height: 80vh;
		}
	}


	@media (max-width:1440px) {
		padding: 40px 0;
		padding-bottom: 45px;
	}

	@media (max-width:1199px) {
		z-index: 2;
		padding: 40px 0 !important;
		padding-bottom: 45px !important;
	}

	&.sticky {
		padding: 30px 0;
		padding-bottom: 35px;

		#logo {
			height: 97px;

			@media (max-width:1920px) {
				max-width: 16rem;
				height: 75px;
			}
			@media (max-width:1440px) {
				max-width: 16rem;
				height: 75px;
			}

			@media (max-width:991px) {
				height: 59px;
			}
		}

		@media (max-width:1440px) {
			padding: 20px 0;
			padding-bottom: 25px;
		}

		#menu {
			margin-top: 0;
		}
	}

	#header-content {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		@media (max-width:767px) {
			align-items: center;
		}
	}

	#logo {
		width: auto;
		height: 107px;
		transition: $transition-base;
		align-self: flex-start;

		a {
			display: block;
			height: 100%;

			img {
				height: 100%;
				width: auto;
			}
		}

		svg {
			width: 100%;
			height: auto;
		}

		@media (max-width:1920px) {
			height: 84px;
		}

		@media (max-width:1440px) {
			height: 75px;
		}

		@media (max-width:991px) {
			height: auto;
			width: 100%;
			max-width: 300px;
			align-self: auto;
		}
	}

	#search-action {
		.input-group {
			display: flex;
			.form-control {
				width: 250px;
			}
		}
	}

	#menu {
		transition: all 0.4s linear;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		position: relative;
		margin-top: 10px;

		@media (max-width:767px) {
			margin-top: 0;
		}

		#mobile-menu {
			display: none;
			visibility: hidden;
		}

		#menu-main-container {
			display: inline-block;
			width: 100%;
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: left;

				&:hover {

					li {
						opacity: .4;

						&:hover {
							opacity: 1;
						}
					}
				}

				li {
					float: left;
					position: relative;
					margin-right: 1.3895rem;
					transition: $transition-base;

					a {
						font-size: 23px;
						line-height: normal;
						display: block;
						color: white;
						transition: $transition-base;
						text-align: left;

						@media (max-width:1440px) {
							font-size: 1rem;
						}
					}
					&:last-child {
						margin-right: 0;
					}
					&:hover {
						opacity: 1;

						a {
							color: white;
						}

						@media (min-width:1200px) {

							.sub-menu {
								opacity: 1;
	
								li {
									opacity: 1;
								}
							}
						}
					}

					&:last-child {

						.sub-menu {
							left: initial;
							right: -0.694rem;

							li {
								&.menu-item-has-children {

									.sub-menu {
										left: initial;
										right: calc(100% + 25px);
									}
								}

								&:not(.menu-item-has-children) {

									a {
										text-align: right;
									}
								}
							}
						}
					}

					&.current-menu-item,
					&.current-menu-ancestor,
					&.current-menu-parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor {
						> a {
							color: $color-turquoise;
						}
						.sub-menu {
							li {
								a {
									color: white;
								}

								&.current-menu-item,
								&.current-menu-ancestor,
								&.current-menu-parent,
								&.current-product-ancestor,
								&.current-product_cat-ancestor,
								&.current-page-ancestor {
									>a {
										color: white !important;
									}
								}
							}
						}
					}

					&.menu-item-has-children {
						
						.sub-menu {
							visibility: hidden;
						}
						&:hover {
							
							.sub-menu {
								visibility: visible;
							}
						}
					}

					.sub-menu {
						opacity: 0;
						text-align: left;
						z-index: 10;
						padding-top: 15px;
						position: absolute;
						left: -0.694rem;
						background-color: $color-primary;
						border-radius: 2px;

						&:hover {

							li {
								opacity: .4;
		
								&:hover {
									opacity: 1;

									> a {
										color: white;
									}
								}

								.sub-menu {
									
									li {
										opacity: 1;
									}
								}
							}
						}

						li {
							white-space: nowrap;
							margin: 0;
							margin-bottom: 15px;
							padding: 0 0.694rem;
							float: none;
							a {
								font-size: 1rem;
								line-height: normal;
								display: block;
								color: white;

								@media (max-width:1440px) {
									font-size: 18px;
								}
							}
							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								> a {
									color: $color-turquoise;
								}
							}

							//second level
							&.menu-item-has-children {
								position: relative;
								
								> a {
									position: relative;
									display: flex;
									align-items: center;
									justify-content: space-between;
									
									&::after {
										content: url('../images/plus.svg');
										position: relative;
    								margin-left: 30px;
									}
								}

								@media (min-width:1200px) {
									&:hover {
										
										> a {
											color: white;
	
											&::after {
												content: url('../images/minus.svg');
												margin-top: -6px;
											}
										}
	
										.sub-menu {
											opacity: 1;
										}
								}
								}

								//third level
								.sub-menu {
									padding: 0;
									margin: 0;
									opacity: 0;
									left: 100%;
    							top: 0;
									width: fit-content;
								}
							}
						}
					}

					@media (max-width:1440px) {
						margin-right: 1.111rem;
					}
				}
			}
		}
	}

	#header-extras {
		margin-left: 4rem;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: .375rem;
		z-index: 11;

		.side-nav-btn {
			width: 47px;
			height: 47px;

			&:hover {

				svg {

					path {
						fill: $color-primary;
					}
				}
			}

			@media (max-width: 1199px) {
				display: none;
				visibility: hidden;
			}
		}

		@media (max-width:1920px) {
			margin-left: 3rem
		}

		@media (max-width:1440px) {
			margin-left: 1.5rem
		}
	}

	.main-nav-btn {
		display: none;
		visibility: hidden;
		width: 47px;
		height: 47px;
		margin-left: .375rem;

		.cross {
			display: none;
			visibility: hidden;
		}

		&.is-active {
			
			.burger {
				display: none;
				visibility: hidden;
			}

			.cross {
				display: initial;
				visibility: visible;

				path {
					stroke: $color-primary;
				}
			}
		}

		&:hover {

			svg {

				path {
					fill: $color-primary;
				}
			}
		}

		@media (max-width: 1199px) {
			display: flex;
			visibility: visible;
		}
	}

	ul {
		&:hover {

			li {
				opacity: .4;

				&:hover {
					opacity: 1;

					.sub-menu {
						opacity: 1;

						li {
							opacity: 1;
						}
					}
				}
			}
		}

		li {
			&.current-menu-item,
			&.current-menu-ancestor,
			&.current-menu-parent,
			&.current-product-ancestor,
			&.current-product_cat-ancestor,
			&.current-page-ancestor {

				> a {
					color: $color-turquoise;
				}
			}
		}
	}
}


//side menu
#close-side-nav {
	position: fixed;
	z-index: 22;
	right: -100%;
	top: 3.4375rem;
	visibility: hidden;
	transition: all .3s ease;

	&.is-active {
		visibility: visible;
		right: 3.125rem;
	}

	&:hover {

		svg {
			path {
				fill: $color-purple;
			}
		}
	}
}
#side-menu {
	background-color: $color-purple;
	color: white;
	display: none;
	visibility: hidden;

	&::after {
		font-size: 23px;
		text-align: left;
		opacity: 1;
		padding: 0 50px;
		padding-left: 30px;
	}

	&::before {
		opacity: 1;
		left: 5px;
	}

	ul {

		&::after {
			display: none;
			visibility: hidden;
		}

		li {
			margin-bottom: 15px;
			font-size: 23px;
			transition: $transition-base;
			width: fit-content;
		
			@media (max-width: 991px) {
				font-size: $regular !important;
			}

			&::after {
				display: none;
				visibility: hidden;
			}

			&::before {
				display: none;
				visibility: hidden;
			}

			a {
				padding: 0;
				width: 100%;
				white-space: nowrap;

				&::after {
					display: none;
					visibility: hidden;
				}

				&:hover {
					color: white;
				}
			}

			&.menu-item-has-children {
				&::before {
					display: initial;
					visibility: visible;
					border-top-color: white;
					border-right-color: white;
					opacity: 1;
					top: 65%;
					transform: translateY(-50%) rotate(45deg);
					right: -50px;
				}
			}

			&.current-menu-item {

				>a {
					color: $color-turquoise;
				}
			}

			.sub-menu {
				padding-top: $medium;

				li {
					font-size: $medium;
				}
			}
		}

		&:hover {
			li {
				opacity: .4;

				&:hover {
					opacity: 1;
				}
			}
		}
	}
}

@media (min-width:1200px) {
	.mm {
		background: $color-purple;
		color: white;
		--mm-spn-item-height: 45px;
		--mm-ocd-max-width: 500px;
	}

	.mm-ocd.mm-ocd--open {
		height: 100vh;
		top: 0;
		z-index: 21;
	}

	.mm-ocd__content {
		width: 100vw;
    max-width: calc(100vw / 3);
		padding: 3.4375rem 3.125rem 3.4375rem 4.6875rem;
		background-color: $color-purple;
	}
}


//mobile menu
@media (max-width: 1199px) {

	#menu-main-container {
		display: none !important;
		visibility: hidden;
	}

	#mobile-menu {
		display: block;
		visibility: visible;
	}
	
	.mm-ocd.mm-ocd--open {
		height: calc(100vh - 160px);
		top: 160px;
	
		@media (max-width:991px) {
			height: calc(100vh - 143px);
			top: 143px;
		}
	}
	
	.mm-ocd__content {
		width: 100vw;
		min-width: calc(50vw - 400px);
		max-width: calc(100vw - 400px);
	
		@media (max-width:757px) {
			min-width: 100vw;
			max-width: 100vw;
		}
	}
	
	.mm-ocd__content,
	.mm-spn.mm-spn--light {
		background: $color-primary;
		color: white;
	
		a {
			color: inherit;
		}
	
		#mobile-menu {
			font-size: 1rem;
			line-height: normal;
			&.mm-spn--main {
				&::after {
					display: none;
				}
			}
	
			&.mm-spn--navbar {
				&::after,
				&::before {
					opacity: 1;
					text-align: left;
					font-size: $base;
				}
			}
	
			.menu-container {
	
				&::before,
				&::after {
					display: none;
				}
				
				li {
					margin-bottom: 8px;
					width: fit-content;
					white-space: nowrap;

					&::after {
						display: none;
					}
	
					&::before {
						opacity: 1;
						display: none;
						visibility: hidden;
					}
	
					a {
						padding: 5px 20px;
						padding-right: 0;
						width: fit-content;
						white-space: nowrap;

						&::after {
							display: none;
						}
					}

					&.menu-item-has-children {
						&::before {
							display: initial;
							visibility: visible;
							top: 50%;
							transform: rotate(45deg) translateY(-50%);
							right: -20px;
							width: 12px;
    					height: 12px;
						}

						@media (max-width:767px) {
							width: auto;

							&::before {
								right: 25px;
							}
						}
					}

					.sub-menu {
						padding-top: 1rem;
					}
				}
			}
		}
	}
}
.mm-ocd__backdrop {
	display: none !important;
}

.mm-ocd--open {
	background: transparent !important;
	visibility: visible;

	#side-menu {
		display: block;
		visibility: visible;
	}
}

.mm-spn.mm-spn--navbar ul:before {
	display: none;
}

.mm-spn ul.mm-spn--parent {
	left: -100%;
}