//
// Footer Styles
// --------------------------------------------------

#related-resources {
	background-color: $color-gray;
	padding: 8.889rem 0;
	color: $color-purple;

	h2 {
		margin-bottom: 2.222rem;
	}

}

#footer-shortcuts {
  padding-top: 7.222rem;
	padding-bottom: 8.889rem;
	background-color: $color-gray;  

	.grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 30px;
		list-style: none;
		padding: 0;
		margin: 0;

		@media (max-width:991px) {
			grid-template-columns: 1fr;
		}
	}

	.shortcut {
		background-color: $color-turquoise;
		color: $color-primary;
		padding: 1.667rem 2.778rem;
		padding-left: 1.667rem;
		transition: $transition-base;

		a {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			gap: 30px;
			transition: none;

			@media (max-width:991px) {
				grid-template-columns: 1fr;
			}
		}

		h2,
		p {
			transition: $transition-base;
		}

		h2 {
			margin-bottom: 0.667rem;
		}

		svg.replaced-svg {
			grid-column: 1 / 2;
			path {
					transition: $transition-base;
			}
		}

		div {
			grid-column-start: 3;
			grid-column-end: 7;

			@media (max-width:991px) {
					grid-column-start: 1;
					grid-column-end: 2;
			}
		}

		svg.arrow {
			margin-top: 2.778rem;

			circle {
					fill: transparent;
			}

			path {
					fill: $color-primary;
			}
		}

		&:hover {
			color: $color-turquoise;
			background-color: $color-primary;

			h2,
			p {
				color: $color-turquoise;
			}

			svg.arrow {
					circle {
							fill: $color-turquoise
					}

					path {
							fill: $color-primary;
					}
			}

			svg.replaced-svg {
					path {
							stroke: $color-turquoise;
					}
			}
		}
	}
}

#footer {
	background-color: $color-blue-darker;
	color: white;

	a {
		color: white;

		&:hover {
			color: $color-turquoise;
		}
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		li {
			margin-bottom: 0.3125rem;
			a {
				color: #ffffff;
				&:hover {
					color: $color-turquoise;
				}
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		&.socials {

			a {
				color: $color-turquoise;
				&:hover {
					color: white;
				}
			}
		}

		&.sub-menu {
			display: none;
			visibility: hidden;
		}
	}

	#footer-top {
		padding-top: 60px;
		margin-bottom: 200px;
		font-size: $base;
		line-height: 1.3em;
		display: grid;
		grid-template-columns: 20% 20% 20% 15% 1fr;
		gap: 1.5625rem;
		align-items: flex-start;
		
		.widgets {
			display: flex;
			justify-content: flex-end;
			gap: 0.375rem;
		}

		@media (max-width:991px) {
			grid-template-columns: repeat(3, 1fr) !important;
			gap: 2.5rem;
			margin-bottom: 100px;

			.logo {
				grid-column: 1/3;
				order: 1;
			}

			.widgets {
				order: 2;
				grid: 3 / 4;
				justify-content: flex-start;
			}

			#footer-newsletter {
				order: 6;
			}

			#menu-principal-1,
			#menu-professionnel-1 {
				order: 3;
			}

			#menu-secondaire-1 {
				order: 4;
			}

			.socials {
				order: 5;
			}
		}

		@media (max-width:576px) {
			grid-template-columns: repeat(2, 1fr) !important;
			gap: 1.5rem;
			font-size: 1rem;

			.logo {
				grid-column: 1/2;
			}

			.widgets {
				grid-column: 2 / 3;
				justify-content: flex-end;
			}

			#footer-newsletter {
				grid-column-start: 1;
    		grid-column-end: 3;
			}
		}
	}

	.footer-logos {
		background-color: white;
		padding: $medium 0;

		.logos {
			padding: 0;
			margin: 0;
			list-style: none;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-start;
			gap: $huge;
			margin-left: auto;
			width: fit-content;

			li {
				margin: 0;
			}

			img {
				height: $huge;
				width: auto;
			}
		}
	}

	#footer-bottom {
		font-size: $small;
		padding: 0.6rem 0;
		background-color: white;
		color: $color-blue-darker;
		border-top: 1px solid $color-blue-darker;

		a {
			color: inherit;

			&:hover {
				color: $color-primary;
			}
		}

		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.signature {

			span {
				margin: 0 8px;

				&:last-child {
					margin-right: 0;
					
					a {
						&:hover {
							color: black;
						}
					}
				}
			}
		}

		@media (max-width:991px) {
			font-size: 0.9rem;
		}

		@media (max-width: 576px) {
			
			.container {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				gap: 0.75rem;
			}

			.signature {

				span {
					&:first-child {
						margin-left: 0;
					}
				}
			}
		}
	}
}

// 321
.powered {
  position: relative;
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;

    #logo-alt-footer-trois {
      fill: $color-primary;
      transform: scale(1.2);
    }

    #logo-alt-footer-deux {
      fill: $color-primary;
      transform: scale(1.2);
    }

    #logo-alt-footer-un {
      fill: $color-primary;
      transform: scale(1.2);
    }

    &:after {
      width: 93px;
    }
  }

  svg {
    overflow: visible;
    width: 50px;
    height: 24px;
    margin: 0 0 0 5px;
    position: relative;
    top: -2px;
    vertical-align: middle;
    width: 34px;

    #logo-alt-footer-trois {
      fill: white;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
      transform: scale(1);
      transform-origin: center;
    }

    #logo-alt-footer-deux {
      fill: white;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
      transform: scale(1);
      transform-origin: center;
    }

    #logo-alt-footer-un {
      fill: white;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
      transform: scale(1);
      transform-origin: center;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: $color-primary;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}