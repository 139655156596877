// Base


p, h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.container {
  width: 100%;
  max-width: 2020px !important;
  margin: 0 auto;
  padding: 0 50px;

  @media (max-width: 1440px) {
    padding: 0 40px;
  }

  @media (max-width: 991px) {
    padding: 0 30px;
  }

  @media (max-width: 767px) {
    padding: 0 15px;
  }
}

.small:not(input, select, textarea) {
  font-size: $small; //16px
  line-height: $line-height-small;

  @media (max-width: 991px) {
    font-size: 0.813rem !important;
  }
}

.regular,
p {
  font-size: $regular; //17px
  line-height: normal;

  @media (max-width: 991px) {
    font-size: $regular !important;
    line-height: normal;
  }
}

.medium,
h5 {
  font-size: $medium; //20px
  line-height: 1.556rem;

  @media (max-width: 991px) {
    font-size: $regular !important;
    line-height: normal;
  }
}

.big,
h4 {
  font-size: $big; //30px
  line-height: normal;

  @media (max-width: 991px) {
    font-size: $medium !important;
    line-height: normal;
  }
}

.large:not(input, select, textarea),
h3 {
  font-size: $large; //40px
  line-height: $line-height-big;
  
  @media (max-width: 991px) {
    font-size: $big !important;
    line-height: normal;
  }
}

.massive,
h2 {
  font-size: $massive; // 44px
  line-height: $line-height-big;

  @media (max-width: 991px) {
    font-size: $large !important;
    line-height: normal;
  }

  @media (max-width:576px) {
    font-size: $big !important;
  }
}

.huge,
h1 {
  font-size: $huge; //51py
  line-height: 3.778rem;

  @media (max-width: 991px) {
    font-size: $massive !important;
    line-height: normal;
  }

  @media (max-width:576px) {
    font-size: 1.9rem !important;
  }
}

.btn-primary {
  border: 2px solid $color-primary;
  color: $color-primary;
  background-color: transparent;
  border-radius: 2px;
  padding: 0.4375rem 0.625rem;
  padding-bottom: 0.556rem;
  font-size: $medium;
  line-height: normal;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition-base;
  width: fit-content;

  &.w-icon {
    justify-content: space-between;

    .icon {
      margin-left: 0.625rem;
    }
  }

  &.is-active,
  &:hover {
    background-color: $color-primary;
    color: white;
  }
}

.btn-icon {
  padding: 0.5rem 0.556rem;
  border-radius: 0.111rem;
  color: $color-text;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: $transition-base;
  border: 1px solid transparent;

  @media (max-width:991px) {
    border-width: 0.125rem;
    padding: 0.563rem 0.625rem;
  }

  svg {
    width: inherit;
    height: auto;
  }

  &:hover {
    background-color: $color-gray;
  }

  &.red {
    color: white;
    background-color: $color-red;

    &:hover {
      color: white;
      background-color: $color-red-darken;
      border-color:$color-red-darken;

      svg {
        path {
          stroke: white;
        }
      }
    }
  }

  &.purple {
    color: $color-purple;
    background-color: white;

    svg {
      path {
        stroke: $color-purple;
      }
    }

    &:hover {
      color: white;
      background-color: $color-purple;
      border-color:$color-gray;

      svg {
        path {
          stroke: white;
        }
      }
    }
  }
}

svg {
  &.arrow {
    width: 30px;
    height: auto;
  }
}

.btn-secondary {
  position: relative;
  font-size: $medium; //24px
  line-height: normal;
  padding-left: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;

  @media (max-width: 991px) {
    font-size: $regular !important;
  }

  svg {
    position: absolute;
    top: calc(50% + 3px);
    left: 0;
    transform: translateY(-50%);
    width: 14px;
    height: auto;
    transition: $transition-base;
  }

  &:hover {

    svg {
      left: 5px;
    }
  }
}
