//
// Pro pages Styles
// --------------------------------------------------

body.pro-page {

    .btn-icon.emergency {
        display: none;
    }

    #newsletter-banner {
        background-color: $color-primary;
        color: $color-turquoise;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding: 8px 15px;
        padding-bottom: 10px;
        opacity: 1;
        transition: $transition-base;
        z-index: 1;

        a {
            text-decoration: underline;
            color: inherit;
        }

        .close-banner {
            padding: 0;
            margin: 0;
            border: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
        }

        &.is-hidden {
            opacity: 0;
            transform: translateY(-50px);
        }
    }

    .search-content {

        #searchform {

            .input-group {

                input {
                    color: $color-primary;
                    background-color: $color-turquoise;
                    border-color: $color-primary;

                    &:focus {
                        color: $color-primary !important;
                        border-color: $color-primary !important;
                    }
                }
            }
        }
    }

    #header {
        background-color: $color-turquoise;
        color: $color-primary;

        // &.newsletter-active {
        //     padding-top: 88px;
        // }

        &.sticky {

            #newsletter-banner {
                opacity: 0;
                transform: translateY(-50%);
            }
        }

        #menu {

            #menu-main-container {

                ul {

                    li {

                        a {
                            color: $color-primary;
                        }

                        &.current-menu-item,
                        &.current-menu-ancestor,
                        &.current-menu-parent,
                        &.current-product-ancestor,
                        &.current-product_cat-ancestor,
                        &.current-page-ancestor {
                            > a {
                                color: white;
                            }
                            .sub-menu {
                                li {
                                    a {
                                        color: $color-primary;
                                    }
                                }
                            }
                        }

                        .sub-menu {
                            background-color: $color-turquoise;

                            &:hover {

                                li {
                                    &:hover {

                                        >a {
                                            color: $color-primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .lang-switcher {
            background-color: $color-primary !important;
            color: $color-turquoise !important;

            .langs {
                border-color: $color-primary !important;
            }

            .lang {
                -webkit-text-stroke: 1px $color-turquoise !important;
                color: $color-turquoise !important;

                &.active {
                    color: transparent !important;
                }
            }
        }

        .main-nav-btn {
            background-color: $color-primary;

            svg {

                path {
                    stroke: $color-turquoise;
                    fill: $color-primary;
                }
            }

            &:hover {
                
                svg {
                    path {
                        fill: $color-turquoise;
                    }
                }
            }

            &.is-active {

                .cross {
                    
                    path {
                        stroke: $color-turquoise;
                    }
                }
            }
        }
    }

    #page-header.standard,
    #page-header.standard-header {
        color: white !important;

        #breadcrumb svg.breacrumb-separator path {
            fill: white !important;
        }

        .child-pages {

            li {

                a {
                    color: inherit;
                    border-color: white !important;

                    &.is-active {
                        color: $color-primary;
                        background-color: white;
                    }
                }

                &:hover {

                    a {
                        color: $color-primary;
                        background-color: white;
                    }
                }
            }
        }
    }

    #page-header.resource-header {
        background: linear-gradient(90deg, #004c6c 0, #004c6c 50%,#fff 50%,#fff 100%);

        @media (max-width:1199px) {
            background: none;

            .left-side {
                background-color: $color-primary;
            }
        }
    }
    
    &.page-template-template-pro-resources {
        
        #page-header {
            background-color: $color-primary !important;
        }

        &.fr {

            #resources-form {
                grid-template-columns: repeat(12, 1fr) !important;
    
                
                .target-select {
                    grid-column: 1 / 7;
                }
                
                .format-select {
                    grid-column: 7 / 13;
                }
                
                input {
                    grid-column: 1 / 10 !important;
                }
                
                button,
                .results-number {
                    grid-column: 10 / 13 !important;
                }
                
                @media (max-width:1199px) {
                    grid-template-columns: repeat(2, 1fr) !important;
    
                    .target-select {
                        grid-column: 1 / 2;
                    }
                    
                    .format-select,
                    button,
                    .results-number {
                        grid-column: 2 / 3 !important;
                    }
                    
                    input {
                        grid-column: 1 / 2 !important;
                    }
                }
            }
        }

    }

    #footer {

        #footer-top {
            grid-template-columns: 25% 25% 16% 25% auto;

            #menu-secondaire-1 {
                display: none;
            }

            .widgets {

                @media(max-width:991px) {
                    justify-content: flex-end;
                }
            }
        }
    }

    #footer-newsletter {

        h2 {
            font-size: inherit !important;
            line-height: inherit !important;
            margin-bottom: .8rem;
        }

        form {
            position: relative;

            label {
                color: white;
            }

            input[type="email"] {
                color: white;
                border-color: white;
                padding: 10px 15px;
                padding-bottom: 12px;

                &::placeholder {
                    color: transparent;
                }
            }

            .gform_fields {
                row-gap: 15px;
            }

            .gfield_checkbox {
                label {
                    font-size: 14px;
                }
            }

            .gform_footer {
                position: absolute;
                top: 11px;
                right: 10px;
                margin: 0;
            }

            .gform_footer input {
                padding: 0;
                border: none;
                max-height: 30px;

                svg {
                    
                    circle {
                        stroke: white;
                        fill: white;
                    }

                    path {
                        fill: $color-blue-darker;
                    }
                }

                &:hover {
                    background-color: transparent;

                    svg {

                        circle {
                            fill: $color-blue-darker;
                        }

                        path {
                            fill: white;
                        }
                    }
                }
            }
        }
    }

    .focus {
        background-color: $color-turquoise;
        color: $color-primary;

        .focus-header {

            svg {
                &.arrow {

                    path {
                        fill: $color-primary;
                    }

                    circle {
                        fill: transparent;
                        stroke: $color-primary;
                    }
                }
            }
        }
    }

    #page-pro-material {
        background-color: $color-gray;
        padding-top: 5.882rem;
        padding-bottom: 9.412rem;

        .products {
            margin-bottom: 7rem;

            .products-list {
                max-height: 30rem;
                overflow-y: scroll;
                list-style: none;
                padding: 0;
                margin: 0;

                @media (max-width:767px) {
                    max-height: 22rem;
                }

                li {
                    margin-bottom: $medium;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 2px solid $color-primary;
                    padding: 15px;

                    p {
                        margin: 0;
                        font-size: inherit;
                        line-height: inherit;
                    }

                    &.completed {
                        background-color: $color-primary;
                        color: white;

                        button {

                            svg {
                                path,
                                rect {
                                    fill: white;
                                }
                            }
                        }

                        input {
                            color: white;
                        }
                    }
                }

                .quantity-content {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    input[type=number] {
                        margin: 0 $medium;
                        background-color: transparent;
                        border: none;
                        text-align: center;
                        font-family: $font-base;
                        font-size: inherit;
                        line-height: inherit;
                        -moz-appearance: textfield;
                        color: inherit;
                        max-width: 3rem;

                        &:focus-visible {
                            outline: none;
                        }
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        appearance: none;
                    }

                    button {
                        padding: 5px;
                        border: none;

                        &:hover {
                            background-color: transparent;
                        }
                    }
                }
            }
        }

        h2 {
            margin-bottom: 2.353rem;
        }

        form {

            .gform_fields {
                grid-template-columns: repeat(2, 1fr);

                @media (max-width:767px) {
                    grid-template-columns: 100%;
                    
                    .gfield {
                        &.gfield--width-full {
                            grid-column: 1 / 2;
                        }
                    }
                }
            }
        }
    }

    #page-pro-homepage {

        .shortcuts {

            .shortcut {
                color: $color-turquoise;
                background-color: $color-primary;

                svg.arrow {

                    path {
                        fill: $color-turquoise;
                    }

                    circle {
                        fill: transparent;
                        stroke: $color-turquoise;
                    }
                }

                svg.replaced-svg {

                    path {
                        stroke: $color-turquoise;
                    }
                }

                &:hover {
                    background-color: $color-turquoise;
                    color: $color-primary;

                    svg.arrow {
                        path {
                            fill: $color-turquoise
                        }

                        circle {
                            fill: $color-primary;
                        }
                    }

                    svg.replaced-svg {
                        path {
                            stroke: $color-primary;
                        }
                    }
                }
            }
        }
    }

    .mm-ocd__content, .mm-spn.mm-spn--light {
        background-color: $color-turquoise;
        color: $color-primary;
    }
}